import { Autocomplete } from '@swftbox/style-guide';
import { type FC, useEffect, useMemo } from 'react';
import { type Retailer, type SelectOptionType, useRetailersQuery, CompanyStatus } from 'src/components/Particles';

interface Props {
  onFocus?: () => void;
  onChange?: (retailerId: string | null, retailer?: Retailer | null) => void;
  value: string | null;
  noLabel?: boolean;
  onlyActive?: boolean;
}

export const RetailerSelectInput: FC<Props> = ({ onFocus, onChange, value, noLabel, onlyActive }) => {
  const { retailers, getRetailers, loading } = useRetailersQuery();

  useEffect(() => {
    getRetailers({ page: 1, perPage: 500 });
  }, [getRetailers]);

  const handleChange = (
    selectedRetailer: SelectOptionType | readonly SelectOptionType[] | null
  ) => {
    if (!onChange) {
      return;
    }
    if (!selectedRetailer) {
      onChange(null);
    }
    onChange((selectedRetailer as SelectOptionType).value ?? null);
  };

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const retailer = retailers.find((retailer: Retailer) => retailer.id === value);
    return retailer ? { label: retailer.name, value } : null;
  }, [value, retailers]);

  const options = useMemo(() => {
    return retailers
      ?.filter((retailer) => !onlyActive || retailer.status === CompanyStatus.active)
      ?.map((retailer) => ({ label: retailer.name, value: retailer.id }))
      || [];
  }, [retailers]);

  return (
    <Autocomplete
      isLoading={loading}
      isClearable
      isMulti={false}
      label={noLabel ? undefined : 'Retailer'}
      onFocus={onFocus}
      onChange={handleChange}
      options={options}
      placeholder="Select retailer"
      value={selectedOption}
    />
  );
};
