import { isArray } from '@apollo/client/utilities';
import { Box, Button, HStack, Tooltip } from '@swftbox/style-guide';
import { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import FilterDropdown from 'src/components/Molecules/FilterDropdown/FilterDropdown';
import {
  AccountStatus,
  CompanyStatus,
  useAllDriversQuery,
  useCategories,
  useCities,
  useOrdersFilters,
  useOrdersFiltersActions,
  useRetailersQuery,
  useZones,
  type City,
  type Zones,
} from 'src/components/Particles';
interface ListItem {
  name: string;
  id: string;
}

export const OrganizingFilters = () => {
  const dispatch = useOrdersFiltersActions();
  const {
    organizedFilters: {
      toCity,
      or,
      dispatchZone,
      partnerCategoryIds,
      dateFilter,
      driverId,
      retailerId,
      isInternational,
      isRemote,
    },
  } = useOrdersFilters();
  const [zones, setZones] = useState<Zones[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [drivers, setDrivers] = useState<ListItem[]>([]);

  const { query: getCities } = useCities();
  const { query: getZones } = useZones();
  const { categories } = useCategories();
  const { drivers: listOfDriversWithUsers } = useAllDriversQuery({
    isOnline: true,
    userStatus: [AccountStatus.ACTIVE],
  });

  useEffect(() => {
    void getCities({
      onCompleted(data) {
        setCities(
          data.cities.map((city) => ({
            id: city.city,
            city: city.city,
            standardCityName: city.standardCityName,
          }))
        );
      },
    });
    void getZones({
      onCompleted(data) {
        setZones(
          data.zones.map((zone) => ({
            id: zone.zone,
            zone: zone.zone,
            standardZoneName: zone.standardZoneName,
          }))
        );
      },
    });
  }, [getCities, getZones]);

  useEffect(() => {
    if (listOfDriversWithUsers.length) {
      setDrivers(
        listOfDriversWithUsers.map((driver) => ({
          id: driver.id,
          name: driver.user.name,
        }))
      );
    }
  }, [listOfDriversWithUsers]);

  const { retailers, getRetailers } = useRetailersQuery();

  useEffect(() => {
    getRetailers({ page: 1, perPage: 500 });
  }, [getRetailers]);

  const retailerDropdownOptions = useMemo(() => {
    return (
      retailers
        ?.filter((retailer) => retailer.status === CompanyStatus.active)
        ?.map((retailer) => ({
          id: retailer.id,
          name: retailer.name,
        })) || []
    );
  }, [retailers]);

  const handleSelectItem = useCallback(
    (e: ChangeEvent<HTMLInputElement> | 'clear' | string[], id: any, actionType: any) => {
      if (e === 'clear') {
        dispatch({ type: actionType, payload: { [id]: 'clear' } });
        return;
      }
      if (isArray(e)) {
        dispatch({ type: actionType, payload: { [id]: e } });
        return;
      }
      dispatch({ type: actionType, payload: { [id]: e.target.value } });
    },
    [dispatch]
  );
  const handleClearAll = () => {
    dispatch({ type: 'CLEAR_ORGANIZING_FILTERS', payload: {} });
  };

  return (
    <HStack>
      <HStack w="min-content" alignItems="center" justifyContent="end">
        <HStack
          key={'isInternational'}
          border="1px solid #D0D5DD;"
          h="40px"
          lineHeight="40px"
          borderRadius="8px"
          shadow="xs"
          overflow="hidden"
          spacing="0"
        >
          <Button
            p="8px 12px"
            fontSize="x-small"
            bg={isInternational ? '#63c3ec' : 'transparent'}
            color={isInternational ? '#fff' : 'gray.900'}
            variant="grouped"
            _focus={{ opacity: 0.85 }}
            _hover={{ opacity: 0.85 }}
            onClick={() => {
              dispatch({
                type: 'ORDERS_IS_INTERNATIONAL_CHANGE',
                payload: { isInternational: !isInternational },
              });
              dispatch({
                type: 'ORDERS_IS_REMOTE_CHANGE',
                payload: {
                  isRemote: !isInternational && isRemote ? false : !!isInternational
                },
              });
            }}
          >
            International
          </Button>
          <Button
            p="8px 12px"
            fontSize="text-xs"
            bg={isRemote ? '#63c3ec' : 'transparent'}
            color={isRemote ? '#fff' : 'gray.900'}
            variant="grouped"
            _focus={{ opacity: 0.85 }}
            _hover={{ opacity: 0.85 }}
            onClick={() => {
              dispatch({
                type: 'ORDERS_IS_REMOTE_CHANGE',
                payload: {
                  isRemote: !isRemote,
                },
              });
              dispatch({
                type: 'ORDERS_IS_INTERNATIONAL_CHANGE',
                payload: {
                  isInternational: isInternational && !isRemote ? false : !!isInternational,
                },
              });
            }}
          >
            Remote
          </Button>
        </HStack>
        <FilterDropdown
          list={retailerDropdownOptions}
          label="Partner"
          onSelect={handleSelectItem}
          selectedItems={retailerId}
          fieldName="name"
          id="retailerId"
          action="ORDERS_RETAILER_CHANGE"
          isSelectedAllEnabled
        />

        <FilterDropdown
          list={drivers}
          label="Driver"
          onSelect={handleSelectItem}
          selectedItems={driverId}
          fieldName="name"
          id="driver"
          action="ORDERS_DRIVER_CHANGE"
        />
        <FilterDropdown
          list={cities}
          label="City"
          onSelect={handleSelectItem}
          selectedItems={toCity}
          fieldName="city"
          fieldAbbreviation="standardCityName"
          id="city"
          action="ORDERS_CITIES_CHANGE"
        />
        <FilterDropdown
          list={zones}
          label="Zone"
          onSelect={handleSelectItem}
          selectedItems={dispatchZone}
          fieldName="zone"
          fieldAbbreviation="standardZoneName"
          id="zone"
          action="ORDERS_ZONES_CHANGE"
        />
        {/* <FilterDropdown
          list={DeliveryProfiles}
          label="Partners"
          onSelect={handleSelectItem}
          selectedItems={or?.dropProfileKey}
          id="type"
          action="ORDERS_TYPES_CHANGE"
        /> */}
        <FilterDropdown
          list={categories}
          label="Category"
          onSelect={handleSelectItem}
          selectedItems={partnerCategoryIds}
          fieldName="name"
          id="category"
          action="ORDERS_CATEGORIES_CHANGE"
        />
      </HStack>
      <Box w="40px">
        {(!!driverId ||
          !!toCity ||
          !!dispatchZone ||
          !!partnerCategoryIds ||
          !!retailerId ||
          !!or?.dropProfileKey ||
          !!isInternational ||
          !!isRemote ||
          dateFilter?.isSelected) && (
          <Tooltip label="Reset filters" hasArrow>
            <Button
              variant="link"
              color="gray.700"
              onClick={handleClearAll}
              border="1px solid"
              borderColor="rgba(255, 101, 101, 1)"
              boxShadow="xs"
              height="40px"
              minW="40px"
            >
              <SVG src={XIcon} stroke="rgba(255, 101, 101, 1)" fill="transparent" width="16px" />
            </Button>
          </Tooltip>
        )}
      </Box>
    </HStack>
  );
};
