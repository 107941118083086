import { memo, useCallback, useEffect, useMemo } from 'react';
import {
  Text,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SBInput,
  Button,
  Tooltip,
  FormLabel,
  Box,
  Autocomplete,
  Badge,
} from '@swftbox/style-guide';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import type { AssignInternationalInput, Item } from 'src/components/Particles';
import { StatesAndProvinces } from './constant';

interface PackageDetailsFormProps {
  items: Item[];
  currency?: string;
}

const initialPackageDetails = {
  dimensions: {
    depth: 10,
    height: 10,
    unit: 'cm',
    width: 10,
  },
  reference: 'NA',
  weight: 0.25,
  weightUnit: 'kg',
};

const PackageDetailsForm = ({ items, currency }: PackageDetailsFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext<AssignInternationalInput>();

  const stateCode = watch('stateCode');

  const stateCodesOptions = useMemo(
    () =>
      StatesAndProvinces.map((state) => ({
        label: state.name,
        value: state.code,
      })),
    []
  );

  const selectedStateCode = useMemo(
    () => stateCodesOptions.find((option) => option.value === stateCode),
    [stateCode, stateCodesOptions]
  );

  const handleChange = useCallback(
    (option: any) => {
      setValue('stateCode', option?.value || null);
      if (option?.value) {
        clearErrors('stateCode');
      }
    },
    [clearErrors, setValue]
  );

  const { fields, append, remove } = useFieldArray<AssignInternationalInput>({
    control,
    name: 'packageDetails',
  });

  useEffect(() => {
    if (fields && !fields.length) {
      append(initialPackageDetails);
    }
  }, [fields, append]);

  const calculateTotalValue = useCallback(() => {
    return items.reduce((total, item) => {
      return total + (item.price ?? 0) * item.quantity;
    }, 0);
  }, [items]);

  return (
    <Stack gap={4}>
      <Box bg="gray.100">
        <TableContainer
          border="1px solid"
          borderColor="gray.300"
          borderRadius="md"
          textAlign="center"
        >
          <Table variant="unstyled" bg="gray.100">
            <Thead position="relative">
              <Tr bg="gray.200">
                <Th></Th>
                <Th>Weight</Th>
                <Th>Depth(cm)</Th>
                <Th>Width(cm)</Th>
                <Th>Height(cm)</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((item, index) => (
                <Tr key={item.id}>
                  <Td>
                    <Text>Package #{index + 1}</Text>
                  </Td>
                  <Td display="flex" gap="1">
                    <Controller
                      name={`packageDetails.${index}.weight`}
                      control={control}
                      defaultValue={item.weight || 1}
                      render={({ field }) => (
                        <SBInput
                          crossOrigin={undefined}
                          placeholder="22"
                          {...field}
                          error={errors.packageDetails?.[index]?.weight?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`packageDetails.${index}.weightUnit`}
                      control={control}
                      defaultValue={item.weightUnit || ''}
                      render={({ field }) => (
                        <SBInput
                          crossOrigin={undefined}
                          placeholder="kg"
                          {...field}
                          error={errors.packageDetails?.[index]?.weightUnit?.message}
                        />
                      )}
                    />
                  </Td>
                  <Td>
                    <Controller
                      name={`packageDetails.${index}.dimensions.depth`}
                      control={control}
                      defaultValue={item.dimensions?.depth || 10}
                      render={({ field }) => (
                        <SBInput
                          crossOrigin={undefined}
                          placeholder="22"
                          {...field}
                          error={errors.packageDetails?.[index]?.dimensions?.depth?.message}
                        />
                      )}
                    />
                  </Td>
                  <Td>
                    <Controller
                      name={`packageDetails.${index}.dimensions.width`}
                      control={control}
                      defaultValue={item.dimensions?.width || 10}
                      render={({ field }) => (
                        <SBInput
                          crossOrigin={undefined}
                          placeholder="22"
                          {...field}
                          error={errors.packageDetails?.[index]?.dimensions?.width?.message}
                        />
                      )}
                    />
                  </Td>
                  <Td>
                    <Controller
                      name={`packageDetails.${index}.dimensions.height`}
                      control={control}
                      defaultValue={item.dimensions?.height || 10}
                      render={({ field }) => (
                        <SBInput
                          crossOrigin={undefined}
                          placeholder="22"
                          {...field}
                          error={errors.packageDetails?.[index]?.dimensions?.height?.message}
                        />
                      )}
                    />
                  </Td>
                  <Td>
                    <Tooltip hasArrow label={`remove package ${index + 1}`}>
                      <Button
                        variant="link"
                        _hover={{ textDecor: 'none' }}
                        color="red"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <span>X</span>
                      </Button>
                    </Tooltip>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <HStack w="97%" justifyContent="end" mb="4">
            <Button
              w="20%"
              onClick={() => {
                append({
                  weight: 1,
                  weightUnit: '',
                  reference: 'NA',
                  dimensions: { depth: 10, width: 10, height: 10, unit: 'cm' },
                });
              }}
            >
              Add Package
            </Button>
          </HStack>
        </TableContainer>
      </Box>
      <HStack>
        <FormLabel w="30%">State Code: </FormLabel>
        <Controller
          name="stateCode"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              onChange={handleChange}
              value={selectedStateCode ?? null}
              options={stateCodesOptions}
              placeholder="Choose state/province..."
              error={error?.message}
            />
          )}
        />
      </HStack>
      <HStack>
        <FormLabel w="30%">Post Code: </FormLabel>
        <Controller
          name={'postCode'}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <SBInput
              crossOrigin={undefined}
              placeholder="ex. 000000"
              {...field}
              error={errors.postCode?.message}
            />
          )}
        />
      </HStack>
      <Stack gap="2">
        <HStack>
          <FormLabel w="23%">*Confirm Item(s) value: </FormLabel>
          <HStack w="77%">
            <Controller
              name={'totalItemsPrice'}
              control={control}
              defaultValue={calculateTotalValue()}
              render={({ field }) => (
                <SBInput
                  crossOrigin={undefined}
                  placeholder="22"
                  {...field}
                  error={errors.totalItemsPrice?.message}
                />
              )}
            />
            <Badge p="19px 13px" fontWeight="medium" borderRadius="8px" border="1px solid #D0D5DD">
              {currency}
            </Badge>
          </HStack>
        </HStack>
        <HStack>
          <FormLabel w="30%">*Description: </FormLabel>
          <Controller
            name={'description'}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <SBInput
                crossOrigin={undefined}
                placeholder="ex. liquid items"
                {...field}
                error={errors.description?.message}
              />
            )}
          />
        </HStack>
      </Stack>
      <HStack w="100%">
        <Text color="gray.500" fontSize="text-sm" ps="1" mb="-3">
          **Item names must be 50 characters or less. Extra characters will be removed automatically
          to ensure proper processing.
        </Text>
      </HStack>
    </Stack>
  );
};

export default memo(PackageDetailsForm);
