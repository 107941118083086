import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SBMenuDotsVerticalIcon,
  toast,
} from '@swftbox/style-guide';
import { openOrdersShippingLabels } from 'src/components/Pages/Orders/Utils/pdf.helper';
import {
  useAddOrdersToJob,
  useAssignOrder,
  useCreateOrdersJob,
  useKeydown,
  useOrderModalActions,
  useReceiveOrders,
  useSyncOrders,
} from 'src/components/Particles';
import { useAuthContext, useOrderSelection } from 'src/components/Particles/Contexts';
import styles from '../style.module.scss';
import { ScanOrders } from './Modal/ScanOrders';
import { AssignDriver } from 'src/components/Organisms';
import { useCallback, useState } from 'react';

export function SelectionOptions() {
  const [jobId, setJobId] = useState('');
  const dispatch = useOrderModalActions();
  const { selectedOrders, unselectAll } = useOrderSelection();
  const { createOrdersJob, loading: submittingOrdersJob } = useCreateOrdersJob();
  const { syncOrders } = useSyncOrders();
  useKeydown('Escape', unselectAll);
  const { user } = useAuthContext();
  const { assignOrder } = useAssignOrder();
  const { addOrdersToJob } = useAddOrdersToJob();
  const { receiveOrders, loading: receivingOrders } = useReceiveOrders();

  function goToPrintScreen() {
    void openOrdersShippingLabels(Array.from(selectedOrders.values()));
  }

  function performSyncOrders() {
    void syncOrders({
      onCompleted: (data) => {
        toast.success(data.fixOrdersElasticSync.message);
      },
      payload: {
        ordersSwftboxTracking: [...selectedOrders.values()].map((order) => order.swftboxTracking),
      },
    });
  }

  const onSubmitComplete = (message: string) => {
    toast.success(message);
    close();
  };

  function createRouteForSelectedOrders() {
    const orderIds = [...selectedOrders.values()].map((order) => order.id);
    void createOrdersJob({ onCompleted: onSubmitComplete, ids: orderIds });
  }
  const onCompleted = (message: string) => {
    toast.success(message);
    close();
  };

  const onDriverAssignment = useCallback(
    async (driverId: string) => {
      const orderIds = [...selectedOrders.values()].map((order) => order.id);
      if (!orderIds.length) return false;
      try {
        if (jobId) {
          void addOrdersToJob({
            onCompleted,
            input: {
              orderIds,
              routeId: jobId,
            },
          });
          unselectAll();
          return true;
        }
        const response = await assignOrder({
          payload: {
            orderIds,
            driverId,
          },
        });
        toast.success(response.data?.createQuickRoute.message);
        unselectAll();
        return true;
      } catch {
        return false;
      }
    },
    [assignOrder, jobId]
  );

  const receiveSelectedOrders = () => {
    const ordersSwftboxTracking = [...selectedOrders.values()].map(
      (order) => order.swftboxTracking
    );
    void receiveOrders({
      onCompleted: onSubmitComplete,
      receiveOrdersInput: {
        ordersSwftboxTracking,
      },
    });
  };

  if (!selectedOrders.size) return null;
  return (
    <HStack align="center" className={styles.tableSelection}>
      <HStack gap="10">
        <ScanOrders
          openElement={
            <Button
              mx={1}
              variant="link"
              color="#fff"
              isLoading={submittingOrdersJob}
              textDecor="underline"
            >
              Selected Orders ({selectedOrders.size})
            </Button>
          }
        />

        <HStack>
          <Button
            mx={1}
            color="primary.600"
            bg={'#fff'}
            borderColor={'primary.600'}
            onClick={() => {
              goToPrintScreen();
            }}
          >
            Shipping labels
          </Button>
          <Button
            mx={1}
            color="primary.600"
            bg={'#fff'}
            borderColor={'primary.600'}
            onClick={createRouteForSelectedOrders}
            isLoading={submittingOrdersJob}
          >
            Create Route
          </Button>
        </HStack>
        <HStack>
          <AssignDriver
            entityName="order"
            onAssign={onDriverAssignment}
            showExistingRoutes={true}
            handleSelectedJob={setJobId}
          >
            <Button mx={1} w="150px" disabled={!selectedOrders.size}>
              Assign
            </Button>
          </AssignDriver>
          <Button
            mx={1}
            onClick={receiveSelectedOrders}
            isLoading={receivingOrders}
            disabled={!selectedOrders.size}
            w="150px"
          >
            Receive
          </Button>
        </HStack>
      </HStack>
      <HStack>
        <Button mx={1} variant="link" color="#fff" onClick={unselectAll} textDecor="underline">
          Clear Selection
        </Button>
        <Menu>
          <MenuButton as="button">
            <Button mx={1} variant="link" color="#fff" textDecor="underline">
              <SBMenuDotsVerticalIcon width="15px" />
            </Button>{' '}
          </MenuButton>
          <MenuList>
            <MenuItem fontSize="12px" color="gray.500">
              Download Report
            </MenuItem>
            {['SuperAdmin', 'Admin'].some((role) => user?.roles?.includes(role)) && (
              <MenuItem onClick={performSyncOrders} fontSize="12px" color="gray.500">
                Sync Orders
              </MenuItem>
            )}
            {['SuperAdmin', 'Admin'].some((role) => user?.roles?.includes(role)) && (
              <MenuItem
                fontSize="12px"
                color="gray.500"
                onClick={() => {
                  dispatch({
                    type: 'OPEN_CANCEL_ORDER',
                    payload: Array.from(selectedOrders.values()),
                  });
                }}
              >
                Cancel Orders
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
}
