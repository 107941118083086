import { Button, Flex, HStack } from '@swftbox/style-guide';
import { type JobStatusFilter, type JobTypeFilter } from 'src/components/Particles';
import { StatsMocking, TypesMocking } from '../mock';

interface JobFilterProps {
  onStatusChange: (value?: JobStatusFilter) => void;
  statusFilter?: JobStatusFilter[];
  onTypeChange: (value?: JobTypeFilter) => void;
  typeFilter?: JobTypeFilter[];
  isExpressFilter?: boolean;
  onExpressFilter: (value?: boolean) => void;
}
export function JobsFilter(props: JobFilterProps) {
  const {
    onStatusChange,
    onTypeChange,
    onExpressFilter,
    statusFilter,
    typeFilter,
    isExpressFilter,
  } = props;

  return (
    <Flex align="center">
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        {TypesMocking.map((type) => (
          <Button
            key={type.header}
            variant="grouped"
            bg={typeFilter?.includes(type.value as JobTypeFilter) ? 'gray.100' : 'transparent'}
            onClick={() => {
              onTypeChange(type.value as JobTypeFilter);
            }}
          >
            {type.header}
          </Button>
        ))}
      </HStack>
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        {StatsMocking.map((stat) => (
          <Button
            key={stat.header}
            variant="grouped"
            bg={
              statusFilter?.length === 1 && statusFilter?.includes(stat?.value as JobStatusFilter)
                ? 'gray.100'
                : 'transparent'
            }
            onClick={() => {
              onStatusChange(stat.value as JobStatusFilter);
            }}
          >
            {stat.header}
          </Button>
        ))}
      </HStack>
      <HStack
        border="1px solid #D0D5DD;"
        h="40px"
        lineHeight="40px"
        borderRadius="8px"
        shadow="xs"
        overflow="hidden"
        w="fit-content"
        spacing="0"
        mr="2"
      >
        <Button
          key={'express'}
          variant="grouped"
          bg={isExpressFilter ? 'gray.100' : 'transparent'}
          onClick={() => {
            onExpressFilter(!isExpressFilter);
          }}
        >
          Express
        </Button>
      </HStack>
    </Flex>
  );
}
