import {
  Badge,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  SBInput,
  SBModal,
  SBTrashIcon,
  Text,
  toast,
  VStack,
} from '@swftbox/style-guide';
import { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { AddressCard, SchedulingCard } from 'src/components/Organisms';
import { stateBadgeColor, useOrderBySwftboxTrackingQuery } from 'src/components/Particles';
import {
  JobOrder,
  useAddOrdersToJob,
  type SequenceStops,
} from 'src/components/Particles/resolvers/Jobs';
import scanSuccessSound from 'src/assets/scanSuccessSound.mp3';
import { useRemoveOrderFromJob } from 'src/components/Particles/resolvers/Jobs/Services/RemoveOrderFromJob.service';
import { OrderRow } from './ViewAllStopOrders/OrderRow';

interface AllStopsOrdersProps {
  routeId: string;
  allOrders: JobOrder[];
  isOpen: boolean;
  onClose: () => void;
}
const successSound = new Audio(scanSuccessSound);

export const AllStopsOrders = ({ allOrders, routeId, isOpen, onClose }: AllStopsOrdersProps) => {
  const [swftboxTracking, setSwftboxTracking] = useState('');
  const [flashSuccessBorder, setFlashSuccessBorder] = useState(false);
  const { addOrdersToJob } = useAddOrdersToJob();
  const { removeOrdersFromJob } = useRemoveOrderFromJob();
  const { getOrderBySwftboxTracking } = useOrderBySwftboxTrackingQuery({
    onCompleted: ({ OrderBySwftboxTracking }) => {
      if (!!OrderBySwftboxTracking.id) {
        addOrdersToJob({
          input: { orderIds: [OrderBySwftboxTracking.id], routeId },
          onCompleted: (message) => {
            toast.success(message);
            successSound.play().catch(console.log);
            setFlashSuccessBorder(true);
            setTimeout(() => {
              setFlashSuccessBorder(false);
            }, 1000);
            setSwftboxTracking('');
          },
        });
      }
    },
  });
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const value = e.currentTarget.value ?? '';
        const text = value.indexOf('<>') > 0 ? value.slice(0, value.indexOf('<>')) : value;
        getOrderBySwftboxTracking(text);
      }
    },
    [getOrderBySwftboxTracking]
  );
  const handleRemoveOrderFromJob = (swftboxTracking: string) => {
    removeOrdersFromJob({
      input: { routeId, swftboxTracking },
      onCompleted: (message) => {
        toast.success(message);
      },
    });
  };
  return (
    <>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        type="general"
        size="6xl"
        isCentered={false}
        header={
          <Box mt="4" mb="3">
            <Box bg="gray.100" borderRadius="8px" p="3">
              <Box color="primary.700" fontSize="text-lg">
                <SBInput
                  label="Scanning Orders"
                  placeholder="Start Scanning..."
                  onKeyDown={handleKeyDown}
                  value={swftboxTracking}
                  onChange={(e) => {
                    setSwftboxTracking(e.target.value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        }
        body={
          <Box
            bg="gray.100"
            borderRadius="8px"
            p="3"
            minW="fit-content"
            _after={{
              border: '5px solid #00B976',
              content: flashSuccessBorder ? "''" : false,
              position: 'fixed',
              right: 0,
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Grid
              gridTemplateColumns="160px 200px 180px 280px 18px"
              gridAutoFlow="row"
              justifyContent="space-between"
              color="gray.700"
              fontWeight="semibold"
              borderBottom="1px solid"
              borderColor="gray.500"
              gap="3"
              py="3"
              mb="5"
              fontSize="text-sm"
              position="sticky"
              top="-8px"
              bg="gray.100"
              zIndex="3"
            >
              <GridItem>Stop and action</GridItem>
              <GridItem>Status</GridItem>
              <GridItem>Timing</GridItem>
              <GridItem>Address</GridItem>
              <GridItem></GridItem>
            </Grid>
            <VStack w="100%">
              {allOrders?.map((order) => {
                return (
                  <OrderRow
                    order={order}
                    handleRemoveOrderFromJob={handleRemoveOrderFromJob}
                    isPickup={true}
                  />
                );
              })}
            </VStack>
          </Box>
        }
        footer={
          <Button variant="outline" mr={3} onClick={onClose}>
            Back
          </Button>
        }
      />
    </>
  );
};
