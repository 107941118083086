import { type FC, useMemo } from 'react';
import { Autocomplete } from '@swftbox/style-guide';
import {
  useAllDriversQuery,
  type SelectOptionType,
  type DriverWithoutPagination,
  AccountStatus,
} from 'src/components/Particles';

interface Props {
  onFocus?: () => void;
  onChange?: (driverId: string | null) => void;
  value: string | null;
  disabled?: boolean;
}

export const DriverSelectInput: FC<Props> = ({ onFocus, onChange, value, disabled }) => {
  const { drivers, loading } = useAllDriversQuery({
    userStatus: [AccountStatus.ACTIVE],
  });

  const handleChange = (selectedDriver: SelectOptionType | readonly SelectOptionType[] | null) => {
    if (!onChange) {
      return;
    }
    if (!selectedDriver) {
      onChange(null);
      return;
    }
    onChange((selectedDriver as SelectOptionType).value ?? null);
  };

  const selectedOption = useMemo(() => {
    if (!value) return null;
    const driver = drivers.find((driver: DriverWithoutPagination) => driver.id === value);
    return driver ? { label: driver.user.name, value } : null;
  }, [value, drivers]);

  return (
    <Autocomplete
      isDisabled={disabled}
      isLoading={loading}
      isClearable
      isMulti={false}
      label="Driver"
      onFocus={onFocus}
      onChange={handleChange}
      options={drivers?.map((driver) => ({ label: driver.user.name, value: driver.id })) || []}
      placeholder="Select driver"
      value={selectedOption}
    />
  );
};
