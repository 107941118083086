import {
  Badge,
  Box,
  Button,
  HStack,
  IconWText,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SBInput,
  Text,
  Tooltip,
  useDisclosure,
} from '@swftbox/style-guide';
import {
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type KeyboardEvent,
  type ReactElement,
} from 'react';
import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';
import VerifyIcon from 'src/assets/icons/General/verify.svg';

import scanSuccessSound from 'src/assets/scanSuccessSound.mp3';
import {
  formatDate,
  Order,
  useOrderBySwftboxTrackingQuery,
  useOrderTimelinesQuery,
} from 'src/components/Particles';
import { statusNameMask } from '../../Filters';
import { OrdersStatusFilter } from '../../../order.types';
const removeNullFromString = (string: string) => string.replace(/(?:null\s*-|null|- null)/g, ' ');
function getFailedAttemptReason(timeline?: any[]) {
  if (!timeline) return '';
  const failedAttemptTimeline = timeline
    .filter((item) => item.action.toLowerCase() == 'failed')
    .sort((t1, t2) => (new Date(t1.date) > new Date(t2.date) ? -1 : 0))
    .at(0);
  if (!failedAttemptTimeline?.failedAttemptReason) return '';

  return failedAttemptTimeline.failedAttemptReason;
}
interface OpenElementProps {
  onClick: () => void;
}

interface SortingModeProps {
  openElement?: ReactElement<OpenElementProps>;
}
const successSound = new Audio(scanSuccessSound);

export function SortingMode(props: SortingModeProps) {
  const inputRef = useRef(null);
  const { getOrderTimeline, orderTimeline } = useOrderTimelinesQuery();

  const { openElement } = props;
  const [swftboxTracking, setSwftboxTracking] = useState('');
  const [flashSuccessBorder, setFlashSuccessBorder] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getOrderBySwftboxTracking, loading } = useOrderBySwftboxTrackingQuery({
    onCompleted: ({ OrderBySwftboxTracking }) => {
      setOrder(OrderBySwftboxTracking);
      getOrderTimeline({
        variables: {
          orderId: OrderBySwftboxTracking.id,
        },
      });
      successSound.play().catch(console.log);
      setFlashSuccessBorder(true);
      setTimeout(() => {
        setFlashSuccessBorder(false);
      }, 1000);
      setSwftboxTracking('');
    },
  });
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const value = e.currentTarget.value ?? '';
        const text = value.indexOf('<>') > 0 ? value.slice(0, value.indexOf('<>')) : value;
        getOrderBySwftboxTracking(text);
      }
    },
    [getOrderBySwftboxTracking]
  );

  const UsedOpenElement = useMemo(() => {
    if (!isValidElement(openElement)) {
      return (
        <Button
          bg="rgba(63, 209, 165, 1)"
          color="#fff"
          onClick={onOpen}
          fontWeight="bolder"
          fontSize="x-small"
        >
          Sorting Mode
        </Button>
      );
    }

    return cloneElement<OpenElementProps>(openElement, {
      onClick: onOpen,
    });
  }, [onOpen, openElement]);
  const { consumer, location } = useMemo(
    () =>
      order?.isReverse
        ? { consumer: order.retailer, location: order?.to }
        : { consumer: order?.customer, location: order?.to },
    [order]
  );
  const { name, bg, border, color } = useMemo(
    () => statusNameMask((order?.status as OrdersStatusFilter) || ' '),
    [order]
  );
  const handleFocusInput = () => inputRef?.current && inputRef.current.focus();
  useEffect(() => {
    // Check if the modal is open and the input element exists
    if (isOpen && inputRef.current) {
      handleFocusInput();
      // Focus on the input element
    }

    return () => {
      setOrder(null);
      setSwftboxTracking('');
    };
  }, [isOpen]);
  return (
    <>
      {UsedOpenElement}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent
          p="0"
          onClick={handleFocusInput}
          borderRadius="18px"
          border="5px solid #00B976"
          borderColor={order ? '#00B976' : '#ff3a6e'}
        >
          <ModalBody px="0" mb="0">
            <SBInput
              position="absolute"
              left="-9999px"
              placeholder="Start Scanning..."
              onKeyDown={handleKeyDown}
              ref={inputRef}
              value={swftboxTracking}
              onChange={(e) => {
                setSwftboxTracking(e.target.value);
              }}
            />

            <Box bg="gray.100" p="3" borderRadius="18px">
              {!!order ? (
                <Box boxShadow="xs" p="8px" w="100%">
                  <Box>
                    <HStack justifyContent="space-between" w="100%" position="relative">
                      <Text fontWeight="bold" fontSize="display-2xl" width="calc(100% - 10px)">
                        {removeNullFromString(
                          `${location?.dispatchCity} - ${location?.dispatchZone}`
                        )}
                      </Text>
                      <Box position="absolute" right="5px" top="18px">
                        {location?.isVerified && <SVG src={VerifyIcon} width="45px" />}
                      </Box>
                    </HStack>
                    <HStack spacing="0" position="relative">
                      <Text lineHeight="1.1" fontSize="display-md" noOfLines={2} mt="1" mb="1.5">
                        {(
                          location?.addressLine1 +
                          ' ' +
                          (location?.addressLine2 ?? '') +
                          ', ' +
                          (location?.city ?? '')
                        ).replace(/(?:null\s*-|null|- null)/g, ' ')}
                      </Text>
                    </HStack>
                    <HStack my="1.5">
                      <Badge
                        py="1px"
                        fontWeight="medium"
                        borderRadius="8px"
                        // width="130px"
                        px="3"
                        textAlign="center"
                        fontSize="display-md"
                        border="1px solid"
                        borderColor={border}
                        bg={bg}
                        color={color}
                        textTransform="capitalize"
                      >
                        {name}
                      </Badge>
                      {!!order.failedAttemptCount && (
                        <Badge
                          py="1px"
                          fontWeight="medium"
                          borderRadius="8px"
                          textAlign="center"
                          fontSize="display-md"
                          border="1px solid rgba(255, 101, 101, 1)"
                          bg="#fff"
                          w="45px"
                          color="rgba(255, 101, 101, 1)"
                          textTransform="capitalize"
                          mb="1"
                          mr="0.5"
                        >
                          <Text as="span" fontSize="text-lg">
                            x
                          </Text>
                          {order.failedAttemptCount}
                        </Badge>
                      )}
                    </HStack>
                    {!!getFailedAttemptReason(orderTimeline) && (
                      <Text
                        my="1.5"
                        fontSize="display-md"
                        border="1px solid rgba(255, 101, 101, 1)"
                        color="rgba(255, 101, 101, 1)"
                        bg="#fff"
                        borderRadius="8px"
                        p="2"
                        width="fit-content"
                      >
                        {getFailedAttemptReason(orderTimeline)}
                      </Text>
                    )}
                    {order.failedAttemptCount >= 3 && (
                      <Badge
                        my="1.5"
                        py="2px"
                        fontWeight="medium"
                        borderRadius="8px"
                        px="3"
                        textAlign="center"
                        bg="#F6DCFF"
                        color="#6764EC"
                        fontSize="display-sm"
                        border="1.5px solid #6764EC"
                        textTransform="capitalize"
                      >
                        Eligible for RTO
                      </Badge>
                    )}

                    <Text fontSize="display-md" my="1.5" fontWeight="semibold">
                      {order.reference} {' - '} {order.swftboxTracking}
                    </Text>
                    {order.packageCount > 1 && (
                      <Tooltip label="Package Count" hasArrow>
                        <Box
                          bg="rgba(255, 58, 110, 1)"
                          color="#fff"
                          p="0px 12px"
                          my="1.5"
                          borderRadius="12px"
                          w="fit-content"
                        >
                          <IconWText
                            color="inherit"
                            text={`x${order.packageCount?.toString()}`}
                            fontSize="display-md"
                            fontWeight="bold"
                            spacing="3"
                            Icon={
                              <SVG
                                src={PackageIcon}
                                width="30px"
                                height="30px"
                                stroke="currentColor"
                              />
                            }
                          />
                        </Box>
                      </Tooltip>
                    )}
                    <HStack fontWeight="bold" fontSize="display-md" my="1.5">
                      {order?.timing?.slaStart && (
                        <>
                          Before :<Text ml="1">{formatDate(order?.timing?.slaEnd, 'HH:mm')},</Text>
                          <Text>{formatDate(order?.timing?.slaEnd, 'MMM dd')}</Text>
                        </>
                      )}
                    </HStack>
                    <Text fontWeight="bold" fontSize="display-lg" my="1.5">
                      {(order.isReverse ? consumer?.name : location?.name) || '-'}
                    </Text>
                  </Box>
                </Box>
              ) : (
                <Box
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="300px"
                  fontSize="display-lg"
                  color="gray.700"
                  fontWeight="semibold"
                >
                  {loading ? <>Loading...</> : <>Please Start Scanning</>}
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
