import { type ElasticSearchSortOptions, type SortOptions } from '../../SharedTypes';
import { type OptimizerStatus, type Job } from '../Queries';

export interface AddJobResponse {
  message: string;
}

export enum JobStatusFilter {
  NotAssigned = 'notAssigned',
  Assigned = 'assigned',
  Started = 'started',
  Completed = 'completed',
  Failed = 'failed',
}

export enum JobTypeFilter {
  rto = 'rto',
  lastMile = 'Delivery',
  partnerPickup = 'Partner_Pickup',
}

export interface JobsResponse {
  data: Job[];
  pageCount: number | undefined;
  total: number;
}

export enum JobSortableFields {
  createdAt = 'createdAt',
}

export interface DateRangeFilter {
  dateRange?: { from?: string; to?: string };
}

export interface JobsProps extends DateRangeFilter {
  page: number;
  label?: JobTypeFilter[];
  perPage?: number;
  status?: JobStatusFilter[];
  search?: string[];
  order?: SortOptions | ElasticSearchSortOptions;
  orderBy?: JobSortableFields;
  isExpress?: boolean;
  driverId?: string[];
}

export interface RouteTimeline {
  routeId: string;
  createdAt: Date;
  status: string;
  source: string;
  reference: string;
  label?: string;
  nextStopSequence: number;
  optimizerStatus: OptimizerStatus | null;
  driverId?: string;
  userId?: string;
  action: string;
  description: string;
  actionByUser?: {
    name: string;
    roles: string[];
  };
}
