import { Box, SBModal } from '@swftbox/style-guide';
import {
  useOrderModalActions,
  useOrderModalContext,
  type Order,
  OrderGroupType,
} from 'src/components/Particles';
import {
  DeliveryAttachments,
  OrderDetailsHeader,
  OrderDetailsItems,
  OrderHistory,
  OrderSuperHistory,
  OriginalPayload,
} from './Components';
import { CustomerDetails } from './Components/Tabs/CustomerDetails';
import { useMemo, useState } from 'react';
import { NavigationTabs } from 'src/components/Organisms';
import ExchangeDetails from './Components/Tabs/ExchangeDetails';

enum tabsIds {
  history = 'HISTORY',
  superHistory = 'SUPER_HISTORY',
  customerDetails = 'CUSTOMER_DETAILS',
  items = 'ITEMS',
  deliveryAttachments = 'DELIVERY_ATTACHMENTS',
  exchangeDetails = 'EXCHANGE_DETAILS',
  originalPayload = 'ORIGINAL_PAYLOAD',
}

export const OrderDetailsModal = () => {
  const { orderDetailsIsOpen, data: order } = useOrderModalContext() as {
    orderDetailsIsOpen: boolean;
    data: Order;
  };
  const viewTabs = useMemo(() => {
    const tabs = [
      { name: 'History', id: tabsIds.history },
      { name: 'Detailed History', id: tabsIds.superHistory },
      { name: 'Customer Details', id: tabsIds.customerDetails },
      { name: 'Items', id: tabsIds.items },
    ];
    if (
      order.deliveryNoteUrls?.length ||
      order?.proofOfId?.length ||
      order?.customerProofSignatureUrl ||
      order?.attachments?.length
    ) {
      tabs.push({ name: 'Delivery Attachments', id: tabsIds.deliveryAttachments });
    }

    if (order.groupType === OrderGroupType.EXCHANGE) {
      tabs.push({ name: 'Exchange Details', id: tabsIds.exchangeDetails });
    }

    if (order.integration) {
      tabs.push({ name: 'Original Payload', id: tabsIds.originalPayload });
    }

    return tabs;
  }, [order]);
  const dispatch = useOrderModalActions();
  const [activeTab, setActiveTab] = useState(tabsIds.history);
  const handleTabChange = (id: string) => {
    setActiveTab(id as tabsIds);
  };
  const handleCloseModal = () => {
    dispatch({ type: 'CLOSE_ORDER_DETAILS' });
    setActiveTab(tabsIds.history);
  };
  const groupOrder = order?.groupedOrders?.find((groupOrder) => groupOrder.id !== order.id);
  return (
    <>
      <SBModal
        isOpen={!!order?.id && orderDetailsIsOpen}
        onClose={handleCloseModal}
        header={
          <OrderDetailsHeader
            order={order}
            tabsButtons={
              <NavigationTabs handleChange={handleTabChange} tabs={viewTabs} active={activeTab} />
            }
          />
        }
        size="5xl"
        body={
          <Box h="300px">
            {activeTab === tabsIds.history && <OrderHistory orderId={order?.id} />}
            {activeTab === tabsIds.superHistory && <OrderSuperHistory orderId={order?.id} />}
            {activeTab === tabsIds.customerDetails && <CustomerDetails order={order} />}
            {activeTab === tabsIds.items && (
              <OrderDetailsItems orderId={order?.id} groupOrder={groupOrder} />
            )}
            {activeTab === tabsIds.originalPayload && <OriginalPayload orderId={order?.id} />}

            {activeTab === tabsIds.deliveryAttachments && (
              <DeliveryAttachments
                deliveryNotes={order?.deliveryNoteUrls}
                proofOfId={order?.proofOfId}
                customerProofSignatureUrl={order?.customerProofSignatureUrl}
                attachments={order?.attachments}
              />
            )}
            {activeTab === tabsIds.exchangeDetails && <ExchangeDetails groupOrder={groupOrder} />}
          </Box>
        }
        bordered={false}
        footer={<div />}
      />
    </>
  );
};
