import { HStack } from '@swftbox/style-guide';
import { useMemo } from 'react';
import { AddressCard } from 'src/components/Organisms';
import { type Order } from 'src/components/Particles';

interface DropAddressProps {
  order: Order;
}

export const DropAddress = ({ order }: DropAddressProps) => {
  const currentConsumer = useMemo(
    () =>
      order?.isReverse
        ? { consumer: order.retailer, location: order.from }
        : { consumer: order.customer, location: order.to },
    [order]
  );
  return (
    <>
      <HStack width="300px">
        <AddressCard
          isVerified={currentConsumer.location.isVerified}
          name={
            (order.isReverse ? currentConsumer.location.name : currentConsumer.consumer.name) ?? '-'
          }
          phone={currentConsumer.consumer.phone}
          address={`${
            currentConsumer.location.addressLine1 +
            ' ' +
            (currentConsumer.location.addressLine2 ?? '') +
            ', ' +
            currentConsumer.location.city
          }`}
          zone={`${currentConsumer.location.city ?? currentConsumer.location.country ?? '-'}`}
          location={currentConsumer.location}
          editable={true}
        />
      </HStack>
    </>
  );
};
