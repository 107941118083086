import { Box, Button, HStack, Text, Tooltip, VStack } from '@swftbox/style-guide';
import { memo } from 'react';
import SVG from 'react-inlinesvg';
import VerifyIcon from 'src/assets/icons/General/verify.svg';
import EditIcon from 'src/assets/icons/orders/editAddress.svg';
import AddressHistoryIcon from 'src/assets/icons/orders/calendar-date.svg';

import { useOrderModalActions } from 'src/components/Particles';
import { EditCustomerAddressType } from 'src/components/Particles/Modules/OrderModule/types';

interface AddressCardProps {
  zone: string;
  name: string;
  isVerified: boolean;
  phone?: string;
  address: string;
  editable?: boolean;
  location?: EditCustomerAddressType;
  showHistory?: boolean;
}
export const AddressCard = memo(
  ({
    zone,
    name,
    isVerified,
    phone,
    address,
    editable,
    location,
    showHistory,
  }: AddressCardProps) => {
    const dispatch = useOrderModalActions();
    return (
      <Box
        border="1px solid #D0D5DD"
        boxShadow="xs"
        borderRadius="8px"
        p="8px"
        w="100%"
        ps="7"
        bg="#fff"
      >
        <VStack alignItems="start" spacing="2">
          <HStack justifyContent="space-between" w="100%" position="relative" spacing="0">
            {!!showHistory && (
              <Tooltip label="Address History" hasArrow>
                <Box
                  position="absolute"
                  right="0px"
                  cursor="pointer"
                  top="-4px"
                  onClick={() => {
                    dispatch({
                      type: 'OPEN_ADDRESS_HISTORY',
                      payload: { ...location },
                    });
                  }}
                >
                  <SVG src={AddressHistoryIcon} width="14px" fill="rgb(102, 112, 133)" />
                </Box>
              </Tooltip>
            )}
            <Text
              fontWeight="semibold"
              fontSize="x-small"
              onClick={() =>
                dispatch({
                  type: 'OPEN_DISPATCH_ZONE_ORDER',
                  payload: { ...location },
                })
              }
            >
              {zone.replace(/(?:null\s*-|null|- null)/g, '-')}
            </Text>{' '}
          </HStack>

          <HStack justifyContent="space-between" w="100%" position="relative" spacing="0">
            <Box position="absolute" left="-20px" top="-2px">
              {isVerified && <SVG src={VerifyIcon} width="16px" />}
            </Box>
            <Text fontWeight="semibold" fontSize="x-small">
              {name}
            </Text>
            <Text fontSize="x-small">{phone}</Text>
          </HStack>
          <HStack spacing="0" position="relative">
            {editable && (
              <Box position="absolute" left="-22px" top="-4px">
                <Tooltip label="Edit Location" hasArrow>
                  <Button
                    variant="link"
                    onClick={() =>
                      dispatch({ type: 'OPEN_EDIT_CUSTOMER_ADDRESS', payload: { ...location } })
                    }
                    minW="auto"
                  >
                    <SVG src={EditIcon} width="16px" />
                  </Button>
                </Tooltip>
              </Box>
            )}
            <Tooltip label={address.length > 100 ? address : null} hasArrow>
              <Text
                color="gray.700"
                fontSize="x-small"
                noOfLines={2}
                minHeight={'40px'}
              >
                {address.replace(/(?:null\s*-|null|- null)/g, ' ')}
              </Text>
            </Tooltip>
          </HStack>
        </VStack>
      </Box>
    );
  }
);
