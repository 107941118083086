import { HStack } from '@swftbox/style-guide';
import { type Order } from 'src/components/Particles';
import { useMemo } from 'react';
import { AddressCard } from 'src/components/Organisms';
interface DropAddressProps {
  order: Order;
}
export const DropAddress = ({ order }: DropAddressProps) => {
  const { consumer, location } = useMemo(
    () =>
      order?.isReverse
        ? { consumer: order.retailer, location: order.to }
        : { consumer: order.customer, location: order.to },
    [order]
  );

  const keyword = order.isRemote
    ? 'Remote'
    : order?.internationalDetails?.isAssigned || order.isInternational
    ? 'International'
    : order.isOutOfZone
    ? 'Out of Zone'
    : undefined;
  const zone = useMemo(
    () =>
      keyword
        ? `${keyword} - ${location?.country ?? ''}`
        : `${location?.dispatchCity ?? ''} - ${location?.dispatchZone ?? ''}`,
    [location, order]
  );

  return (
    <>
      <HStack width={{ lg: '240px', '2xl': '280px' }}>
        <AddressCard
          zone={zone}
          isVerified={location.isVerified}
          name={(order.isReverse ? location?.name : consumer?.name) || '-'}
          phone={location.phone ?? consumer?.phone}
          address={`${location.company ?? ''} ${location.addressLine1} 
          ${location.addressLine2 ?? ''} ${location.building ?? ''} ${location.area ?? ''} - 
          ${location.city} ${location.stateProvinceCode ?? ''} ${location.country ?? ''} 
          ${location.zipPostalCode ?? ''}`}
          location={location}
          editable={!order?.isReverse}
          showHistory
        />
      </HStack>
    </>
  );
};
